import React from "react"
import { css } from "@emotion/core"

const IssueHeader = ({ children }) => {

  return (
    <h3
      css={css`
        transform:rotate(-1deg);
        z-index:1;
        text-align: center;
        font-family: Special Elite, serif;
        text-transform: uppercase;
        margin-top:10px;
        span {
          color:white;
          background-color: #101010;
          padding: 10px 10px 1px;
          display:inline;
          box-decoration-break: clone;
        }
        
        @media screen and (min-width: 480px) {
          font-size: 20px;
        }
        @media screen and (min-width: 740px) {
          font-size: 24px;
        }
        @media screen and (min-width: 960px) {
          font-size: 28px;
        }
        font-size: 18px;
        
        &:hover {
        text-decoration: underline;
        color:white;
        }
`
      }
    >
      <span className={'fx-shadow-hard'}>{children}</span>
    </h3>
  )
}

export default IssueHeader
