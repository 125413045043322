import React from "react"
import { css } from "@emotion/core"

const Wide3DHeader = ({ children }) => {

  return (
    <h1
      className={'fx-3d'}
      css={css`
        text-align: center;
        font-family: Work Sans, sans-serif;
        font-weight: 800;
        line-height: 0.8em;
        color: white;
        text-transform: uppercase;
        
        @media screen and (min-width: 480px) {
          font-size: 32px;
          margin: 20px 0;
        }
        @media screen and (min-width: 740px) {
          font-size: 48px;
          margin: 28px 0;
        }
        @media screen and (min-width: 960px) {
          font-size: 64px;
          margin: 36px 0;
        }
        font-size: 28px;
        margin: 20px 0;
`
      }
    >
      <span>{children}</span>
    </h1>
  )
}

export default Wide3DHeader
