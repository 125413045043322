import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Media from "react-bootstrap/Media"
import { css } from "@emotion/core"
import _ from "lodash"
import Credits from "./credits/Credits"
import SectionHeader from "./headers/section-header"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const cssInfoHeader = css`
  font-family: "Work Sans", serif;
  font-weight: 300;
  line-height: 0.8em;
  display: inline-block;
  text-transform: uppercase;
  color:#8c8c8c;
  font-size: 24px;
  margin: 0;
  padding:20px 0 0 0;
  @media (max-width: 740px) {
		  font-size: 18px;
		}
		@media (max-width: 480px) {
		  font-size: 16px;
		}
`

class ComicItemInfo extends React.Component {

  render() {

    const { html, image, title, credits, research, link, links } = this.props

    if ((!html || html.replace(" ", "") === "") && !credits && !links) return null

    const fluid = image.thumb.fluid || image.childImageSharp.fluid

    return (
      <Media
        css={css`
          background-color: #101010;
          color: #ffffff;
          margin-bottom: 40px;
        `}
      >
        {fluid && !link &&
        <Img fluid={fluid} className={"d-none d-md-flex mr-3 w-25 drop-shadow m-3"} alt={title} />}
        {fluid && link &&
        <Link to={link}  className={"d-none d-md-flex mr-3 w-25 drop-shadow m-3"} title={title}><Img fluid={fluid} className={'w-100 h-100'} alt={title} /></Link>}
        <Media.Body css={css`
          padding: 0 20px 20px;
          @media screen and (min-width: 765px) {
            padding:0 20px 0 0;
          }
          `}>
          <h2 css={cssInfoHeader}>
            {title}
          </h2>
          <section css={css`

          h1, h2, h3, h4, h5, .related {
            font-family: Special Elite, serif;
            font-weight: 700;
            margin: 20px 0 0; padding: 0;
            @media (max-width: 960px) {
              margin: 20px 0 10px;
            }
            @media (max-width: 480px) {
              margin-top: 20px;
            }
          }

	h1, .related {
		font-size: 36px;
		@media (max-width: 960px) {
		  font-size: 34px;
		}
		@media (max-width: 740px) {
		font-size:32px;
		}
		@media (max-width: 480px) {
		font-size:30px;
		}
	}

	h2 {
		font-size: 32px;
		@media (max-width: 960px) {
		  font-size: 30px;
		}
    @media (max-width: 740px) {
		font-size:28px;
		}
		@media (max-width: 480px) {
		font-size:26px;
		}
	}

	h3 {
		font-size: 28px;
		@media (max-width: 960px) {
		  font-size: 26px;
		}
    @media (max-width: 740px) {
		font-size:24px;
		}
		@media (max-width: 480px) {
		font-size:22px;
		}
	}

	h4 {
		font-size: 24px;
		@media (max-width: 960px) {
		  font-size: 22px;
		}
    @media (max-width: 740px) {
		font-size:20px;
		}
		@media (max-width: 480px) {
		font-size:18px;
		}
	}

	h5 {
		font-size: 20px;
		@media (max-width: 960px) {
		  font-size: 18px;
		}
    @media (max-width: 740px) {
		font-size:16px;
		}
		@media (max-width: 480px) {
		font-size:14px;
		}
	}
	
	font-family: Bitter, serif;
	
	a {
	color: inherit;
	border-bottom-width: 1px;
	border-bottom-color: #cccccc;
	border-bottom-style: dashed;
	transition: all 0.2s;
	&:hover {
	text-decoration: none;
	border-bottom-style: solid;
	background: rgba(255, 255, 255, 0.2);
	}
	}
	
	hr {
	  border-color: #cccccc;
	}
	
	sup {
	  a.footnote-ref {
	    color: inherit;
	    &:before {
	      content: '[';
	    }
	    &:after {
	      content: ']';
	    }
	  }
	}
	
	ul {
	
    li {
      margin:0;
      font-size: 24px;
    }
    
    margin:0;
    padding: 0 0 20px 40px;
    @media screen and (max-width:480px) {
      font-size: 14px;
      margin: 0;
      padding: 0 0 10px 30px;
    }
    
	}
	
	> p {
	  padding: 0px 0 20px;
	  margin: 0;
	  font-size: 18px;
    @media (max-width: 740px) {
      font-size:16px;
		}
		@media (max-width: 480px) {
      font-size:14px;
      padding: 10px 10px;
		}
	}
	
	pre {
	  color: #ffffff;
	  background: #202020;
	  padding: 10px 20px;
	}
	
	blockquote {
		position: relative;
		font-family: Special Elite, serif;
		font-style: italic;
		padding: 0.6em 1em 0.4em;
		margin:0.5em 0.5em 1em;
		border-left: 8px solid rgba(255, 255, 255, 0.1);
		background: rgba(255, 255, 255, 0.1);
		display: inline-block;
		
		&:before {
			content: '\\201C';
			position: absolute;
			top: -0.5em;
			left: -0.15em;
			color: white;
			font-size: 3em;
			opacity:0.2;
		}
		
	}
`}>
            <div dangerouslySetInnerHTML={{ __html: html }}/>
            {research && research.length > 0 &&
            <div>
              <div className={'related'}>Releated Research</div>
              <ul>
                {research.map(o => {
                  return (
                    <li key={o}><Link to={`/research/${_.kebabCase(o)}`} title={o}>{_.startCase(o)}</Link></li>
                  )
                })}
              </ul>
            </div>
            }

            {links &&
            <div>
              <div className={'related'}>Further Knowledge</div>
              <p>Check out some suggested books and films related to topics covered on this page.</p>
              {links.map(({ title, link, description, img }) => {
                return (
                  <div
                    key={link}
                    css={css`
                  :before, :after {
                    content: '';
                    display: table;
                    clear: both;
                  }
                  
                  a {
                    &:hover { 
                      color: inherit;
                    }
                  }
                  
                  margin-bottom:20px;
                  
                  .img {
                    float:left;
                    margin-right:20px;
                    width: 100px;
                  }`}>
                    <OutboundLink target={"_blank"} href={link}>
                      {img && <Img fixed={img.childImageSharp.fixed} className={"img"} alt={title} />}
                      <h4>{title}</h4>
                    </OutboundLink>
                    <p>{description}</p>
                  </div>
                )
              })

              }
            </div>}
          </section>

          {credits &&
          <div
            css={css`
              font-family: Bitter, serif;
              margin-bottom:20px;
              
              	.credits {
              	  display: flex;
              	  flex-direction: row;
              	  flex-wrap: wrap;                  
                  margin:0;
                  padding:0;
                  > div {
                    flex: 1;
                    margin: 10px;
                    &:first-of-type {
                      margin-left:0;
                    }
                    &:last-of-type {
                      margin-right:0;
                    }
                  }
                  
                  @media screen and (max-width:480px) {
                    font-size: 14px;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                      margin-bottom: 10px;
                    }
                  }
                }
            `}>
            <h2 css={cssInfoHeader}>
              Credits
            </h2>
            <div css={css`
              > div > div {
                  flex: 0 50%;
                  @media screen and (min-width: 480px) {
                    flex: 0 50%
                  }
                  @media screen and (min-width: 540px) {
                    flex: 0 50%
                  }
                  @media screen and (min-width: 600px) {
                    flex: 0 33%
                  }
                  @media screen and (min-width: 740px) {
                    flex: 0 33%
                  }
                  @media screen and (min-width: 960px) {
                    flex: 0 33%
                  }
                  @media screen and (min-width: 1100px) {
                    flex: 0 25%
                  }
              }
            `}>
              <Credits credits={credits}/>
            </div>
          </div>
          }
        </Media.Body>
      </Media>
    )
  }
}

export default ComicItemInfo
