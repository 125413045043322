import React from "react"
import { css } from "@emotion/core"

const ComicHeader = ({ children }) => {

  const isLong = children.toString().length >= 15

  return (
    <div
      className={'fx-3d comic-header'}
      css={css`
        text-align: center;
        font-family: Work Sans, sans-serif;
        font-weight: 800;
        line-height: 0.9em;
        color: white;
        margin: 0;
        text-transform: uppercase;        
        @media screen and (min-width: 480px) {
          font-size: ${(isLong) ? 36 : 48 }px;
        }
        @media screen and (min-width: 960px) {
          font-size: ${(isLong) ? 48 : 60 }px;
        }
        font-size: ${(isLong) ? 28 : 36 }px;
`
      }
    >
      <span>{children}</span>
    </div>
  )
}

export default ComicHeader
