import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import _ from "lodash"
import ComicReader from "../components/comic-reader"
import ComicItemsList from "../components/comic-items-list"
import ComicItemInfo from "../components/comic-item-info"
import SectionHeader from "../components/headers/section-header"
import Wide3DHeader from "../components/headers/wide-3d-header"

class ComicPageTemplate extends React.Component {

  render() {

    // all comics
    const publishedComics = this.props.data.publishedComics.edges

    // series
    const series = this.props.data.series
    const issues = this.props.data.issues.edges
    const allSeriesPages = this.props.data.allSeriesPages.edges

    // issue
    const issue = this.props.data.issue
    const pages = this.props.data.pages.edges
    const comicPage = this.props.data.markdownRemark

    // get current page position in pages
    let currentPageIndex = _.findIndex(pages, { node: { frontmatter: { page: comicPage.frontmatter.page } } })

    // get current page position in all pages
    let currentSeriesPageIndex = _.findIndex(allSeriesPages, { node: { frontmatter: { seriesPage: comicPage.frontmatter.seriesPage } } })

    // get current page position in pages
    let currentIssueIndex = _.findIndex(issues, { node: { frontmatter: { title: issue.frontmatter.title } } })

    let nextIssue = null
    if (currentIssueIndex < issues.length - 1) {
      nextIssue = issues[currentIssueIndex + 1].node
    } else {
      if (series.frontmatter.anthology && currentIssueIndex !== 0) {
        nextIssue = issues[0].node
      }
    }


    let prevIssue = null
    if (currentIssueIndex > 0) {
      prevIssue = issues[currentIssueIndex - 1].node
    } else {
      if (series.frontmatter.anthology && issues.length !== 1) {
        prevIssue = issues[issues.length - 1].node
      }
    }

    let nextPage = (currentPageIndex >= pages.length - 1) ? nextIssue : pages[currentPageIndex + 1].node

    let prevPage = null
    if (currentPageIndex > 0) {
      prevPage = pages[currentPageIndex - 1].node
    } else {
      if (series.frontmatter.anthology && prevIssue) {
        // get pages from previous issue
        //prevPage = _.filter(allSeriesPages, { node: { frontmatter: { issue: prevIssue.frontmatter.id } } }).pop().node
      }
    }

    return (
      <Layout
        location={this.props.location}

        svg={series.frontmatter.svg.publicURL}
      >

        <SEO
          title={`${series.frontmatter.title} | ${issue.frontmatter.title} (Page ${currentPageIndex+1})`}
          description={comicPage.frontmatter.description || comicPage.excerpt}
          ogImageUrl={comicPage.frontmatter.image.childImageSharp.fluid.src}
          comic={series}
          issue={issue}
          page={comicPage}
        />

        <ComicReader
          nextIssue={nextIssue && nextIssue}
          previousIssue={prevIssue}
          firstIssue={issues[0].node}
          comic={series}
          issue={issue}
          page={comicPage}
          nextPage={nextPage}
          previousPage={prevPage || issue}
          latestPage={currentSeriesPageIndex < allSeriesPages.length - 1 ? allSeriesPages[allSeriesPages.length - 1].node : null}/>

        <ComicItemInfo html={comicPage.html} image={comicPage.frontmatter.image} title={`About this page`} research={comicPage.frontmatter.research} links={comicPage.frontmatter.links} />
        <ComicItemInfo html={issue.html} image={issue.frontmatter.image} title={`About ${issue.frontmatter.title}`} credits={issue.frontmatter.credits} link={issue.fields.slug} />
        <ComicItemInfo html={series.html} image={series.frontmatter.promo || series.frontmatter.image}  title={`About ${series.frontmatter.title}`} link={series.fields.slug} credits={series.frontmatter.credits} />

        {issues.length > 1 && <Wide3DHeader>Issues in this Series</Wide3DHeader>}
        <ComicItemsList items={issues} exclude={[issue]}/>

        <Wide3DHeader>Other Comic Series</Wide3DHeader>
        <ComicItemsList items={publishedComics} exclude={[series]}/>
      </Layout>
    )
  }
}

export default ComicPageTemplate

export const squareImage = graphql`
    fragment squareImage on File {
        childImageSharp {
            fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`

export const pageQuery = graphql`
    query ComicPageTemplateQuery($slug: String!, $comicID: String!, $issueID: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            fields { slug }
            ...ComicPageFrontmatter
        }

        pages: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___seriesPage}, filter: {frontmatter: {type: {eq: "comic-page"},  comic: {eq: $comicID},  issue: {eq: $issueID} }}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    ...ComicPageOptimizedFrontmatter
                }
            }
        }

        allSeriesPages: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___seriesPage}, filter: {frontmatter: {type: {eq: "comic-page"},  comic: {eq: $comicID} }}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    ...ComicPageOptimizedFrontmatter
                }
            }
        }
        
        series: markdownRemark(frontmatter: { title: { eq: $comicID }, type: {eq: "comic"} }) {
            id
            excerpt(pruneLength: 160)
            html
            fields { slug }
            ...ComicSeriesFrontmatter
        }

        issue: markdownRemark(frontmatter: { comic: {eq: $comicID}, title: { eq: $issueID }, type: { eq: "comic-issue" } }) {
            id
            html
            fields {
                slug
            }
            ...ComicIssueFrontmatter
        }
        
        issues: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic-issue"}, published: {ne: false},  comic: {eq: $comicID} }}, sort: {fields: frontmatter___date, order: ASC}) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    ...ComicIssueFrontmatter
                }
            }
        }
        
        # Published Comics Series
        publishedComics: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic"}, published: {ne: false}}}) {
            edges {
                node {
                    id
                    fields {slug }
                    ...ComicSeriesFrontmatter
                }
            }
        }
        
    }
`
