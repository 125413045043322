import React from "react"
import { css } from "@emotion/core"
import _ from "lodash"
import BioBox from "./BioBox"

class Credits extends React.Component {
  render() {

    const { credits } = this.props

    return (
      <div css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          `}>
        {_.map(credits, ({ node, name, role }) => {
          return <BioBox credit={node || name} role={role} key={(node && node.name) || name.name} />
        })}
      </div>
    )
  }
}

export default Credits