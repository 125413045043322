import React from "react"
import { Link } from "gatsby"
import _ from "lodash"
import { css } from "@emotion/core"
import Swiper from "react-id-swiper"
import TapeLabelHeader from "./headers/tape-label-header"
import Img from 'gatsby-image'

const ComicItemsList = ({ items, exclude = [], loop=false }) => {

  const params = {
    shouldSwiperUpdate: false,
    rebuildOnUpdate: false,
    //mousewheel: true,
    grabCursor: true,
    slidesPerView: 2,
    spaceBetween: 20,
    slidesPerGroup: 1,
    loop: loop,
    speed: 1500,
    autoHeight: false,
    loopFillGroupWithBlank: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    breakpoints: {
      960: {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 1,
      },
      540: {
        slidesPerView: 3
      }
    },
  }

  const excludeSlugs = _.map(exclude, e => {
    return e.fields.slug
  })

  const filteredItems = _.filter(items, issue => {
    return !excludeSlugs.includes(issue.node.fields.slug)
  })

  if (filteredItems.length === 0) return null

  if (filteredItems.length < 2) {
    params.loop = false
    params.navigation = false
    params.pagination = false
    params.grabCursor = false
    params.allowTouchMove = false
    params.noSwiping = false
    params.allowSlidePrev = false
    params.allowSlideNext = false
    params.autoplay = false
    params.keyboard = false
  }

  return (
    <div
      css={css`
        padding: 0 10px;
					@media screen and (min-width: 480px) {
            padding: 0 14px;
          }
          @media screen and (min-width: 740px) {
            padding: 0 20px;
          }
          @media screen and (min-width: 1000px) {
            padding: 0;
          }
      `}
    >
      <Swiper {...params}>
        {filteredItems.map(({ node }) => {
          return (
            <Link key={`item-list-${node.fields.slug}`} to={node.fields.slug} css={css`
                  text-align:center;
                  color: inherit;
                  h5 {
                    padding:10px;
                    font-family: "Special Elite", serif;
                    color: white;
                    
                    font-size:18px;
                    @media screen and (min-width: 740px) {
                      font-size:22px;
                    }
                    @media screen and (min-width: 1000px) {
                      font-size:24px;
                    }
                  }
                  &:hover {
                    color: inherit;
                    text-decoration: none;
                  }
                `}>
              <Img alt={`${node.frontmatter.title} cover`} fluid={node.frontmatter.image.thumb.fluid} className={"drop-shadow"} alt={node.frontmatter.title} />
              <TapeLabelHeader>{node.frontmatter.title}</TapeLabelHeader>
            </Link>
          )
        })}
      </Swiper>
    </div>
  )
}

export default ComicItemsList
