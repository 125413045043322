import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFastBackward, faFastForward, faPlay } from "@fortawesome/pro-solid-svg-icons"
import Tippy from "@tippy.js/react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import ComicHeader from "./headers/comic-header"
import IssueHeader from "./headers/issue-header"

const ComicReader = ({
                       comic,
                       page,
                       header = true,
                       issue,
                       latestPage,
                       nextPage,
                       previousPage,
                       nextIssue,
                       previousIssue,
                       firstIssue,
                       latestIssue,
                     }) => {

  let colorPage
  //colorActive = Color(colorDefault).saturate(0.3).hex()
  colorPage = '#ffffff'
  //colorDark = Color(colorDefault).darken(0.3).hex()

  let colorTitle = '#fffff'
  let colorTitleHover =  '#fffff'

  let colorNav = '#fffff'
  let colorNavHover = '#fffff'

  return (
    <div className={"comic-reader-wrapper"}>
      <div className={"comic-reader"}>

        <header className={"comic-reader-header"}>
          {/* Comic Header (title, nav, etc) */}
          <nav
            className={"comic-reader-header-nav"}
            css={css`
        
        .svg-inline--fa {
          color: ${colorNav};
          //text-shadow: 3px 3px 0 #101010, -1px -1px 0 #101010, 1px -1px 0 #101010, -1px 1px 0 #101010, 1px 1px 0 #101010;
          filter: drop-shadow(3px 3px 0 #101010) drop-shadow(-1px -1px 0 #101010) drop-shadow(1px -1px 0 #101010) drop-shadow(-1px 1px 0 #101010) drop-shadow(1px 1px 0 #101010);
          font-size: 28px;
        }
        
        a {
        
          
          text-decoration: none;
          color: #ffffff;
        
            .svg-inline--fa{
            &:hover {
                color: ${colorNavHover};
              }
            }
            &:hover {
                color: ${colorTitleHover};
              }
        }
        `}>

            {/* "Heads" Image */}
            <Img fluid={comic.frontmatter.header.childImageSharp.fluid}
                 alt={comic.frontmatter.title}
                 className={"comic-reader-heads d-none d-md-block d-lg-block d-xl-block"}/>

            {/* Column for Navigation Elements */}
            <nav className={"comic-reader-nav"}>

              {/* Comic Navigation */}
              <nav className={"comic-reader-comic-nav"} css={css`
                a {
                  color: ${colorTitle};
                  }
              `}>
                <Link to={comic.fields.slug} title={comic.frontmatter.title}>
                  <ComicHeader>{comic.frontmatter.title}</ComicHeader>
                </Link>
              </nav>

              {/* Issue Navigation */}
              <nav className={"comic-reader-issue-nav"}>

                {/* Previous Issue */}
                {previousIssue ? (
                  <Tippy content={"Previous Issue"} theme={"light-border"}>
                    <Link to={previousIssue.fields.slug} rel="prev" title={'previous issue'}>
                      <h2><FontAwesomeIcon icon={faPlay} flip={"horizontal"}/></h2>
                    </Link>
                  </Tippy>
                ) : <h2 className={"nav-disabled"}><FontAwesomeIcon icon={faPlay} flip={"horizontal"}/></h2>}

                {/* Current Issue Title */}
                <Link to={issue.fields.slug} title={issue.frontmatter.title}>
                  <IssueHeader>
                    {issue.frontmatter.title}
                  </IssueHeader>
                </Link>

                {/* Next Issue */}
                {nextIssue ? (
                  <Tippy content={"Next Issue"} theme={"light-border"}>
                    <Link to={nextIssue.fields.slug} title={'next issue'}>
                      <h2><FontAwesomeIcon icon={faPlay}/></h2>
                    </Link>
                  </Tippy>
                ) : <h2 className={"nav-disabled"}><FontAwesomeIcon icon={faPlay}/></h2>}
              </nav>

              {/* Page Navigation */}
              <nav className={"comic-reader-page-nav"}>

                {/* First Issue */}
                {firstIssue ? (
                  <Tippy content={"First Issue"} theme={"light-border"}>
                    <Link to={firstIssue.fields.slug} title={'first issue'}>
                      <h2><FontAwesomeIcon icon={faFastBackward} fixedWidth={true}/></h2>
                    </Link>
                  </Tippy>
                ) : <h2 className={"nav-disabled"}><FontAwesomeIcon icon={faFastBackward} fixedWidth={true}/></h2>}

                {/* Previous Page */}
                {previousPage ? (
                    <Tippy content={"Previous Page"} theme={"light-border"}>
                      <Link to={previousPage.fields.slug} rel="prev" title={'previous page'}>
                        <h2><FontAwesomeIcon icon={faPlay} flip={"horizontal"} fixedWidth={true}/></h2>
                      </Link>
                    </Tippy>
                  ) :
                  <h2 className={"nav-disabled"}><FontAwesomeIcon icon={faPlay} flip={"horizontal"} fixedWidth={true}/>
                  </h2>}

                {/* Current Position */}
                {page ? (
                    <span className={"fx-stroke"}
                          css={css`color:${colorPage}`}>Page {page.frontmatter.page} of {issue.frontmatter.pages}</span>
                  ) :
                  <span className={"fx-stroke"} css={css`color:${colorPage}`}>{issue.frontmatter.pages} pages</span>
                }

                {/* Next Page */}
                {nextPage ? (
                  <Tippy content={"Next Page"} theme={"light-border"}>
                    <Link to={nextPage.fields.slug} rel="next" title={'next page'}>
                      <h2><FontAwesomeIcon icon={faPlay} fixedWidth={true}/></h2>
                    </Link>
                  </Tippy>
                ) : <h2 className={"nav-disabled"}><FontAwesomeIcon icon={faPlay} fixedWidth={true}/></h2>}

                {/* Latest Page */}
                {latestPage ? (
                  <Tippy content={"Latest Page"} theme={"light-border"}>
                    <Link to={latestPage.fields.slug} title={'latest page'}>
                      <h2><FontAwesomeIcon icon={faFastForward} fixedWidth={true}/></h2>
                    </Link>
                  </Tippy>
                ) : <h2 className={"nav-disabled"}><FontAwesomeIcon icon={faFastForward} fixedWidth={true}/></h2>}
              </nav>

            </nav>
          </nav>
        </header>

        {/* Display the page here */}
        <Img
          alt={`${comic.frontmatter.title} - ${issue.frontmatter.title || firstIssue.frontmatter.title}`}
          className={"comic-reader-page drop-shadow"}
          fluid={ (page && page.frontmatter.image.childImageSharp.fluid) || issue.frontmatter.image.childImageSharp.fluid || firstIssue.frontmatter.image.childImageSharp.fluid}/>

        <nav className={"comic-reader-sub-page-nav fx-stroke"} css={css`
  color: ${colorPage};
  
  a {
    color: white;
    
    &:hover {
          color: ${colorTitleHover};
          .svg-inline--fa{
        color: ${colorTitleHover};
      }
        }
  }
      `}>

          {/* First Issue */}
          {firstIssue ? (
            <Link to={firstIssue.fields.slug} title={'first issue'}>
              <FontAwesomeIcon icon={faFastBackward} fixedWidth={true}/><span
              className={"d-none d-md-inline d-lg-inline d-xl-inline"}> First</span>
            </Link>
          ) : <span className={"nav-disabled"}><FontAwesomeIcon icon={faFastBackward} fixedWidth={true}/><span
            className={"d-none d-md-inline d-lg-inline d-xl-inline"}> First</span></span>}

          {/* Previous Page */}
          {previousPage ? (
            <Link to={previousPage.fields.slug} rel="prev" title={'previous page'}>
              <FontAwesomeIcon icon={faPlay} flip={"horizontal"} fixedWidth={true}/><span
              className={"d-none d-md-inline d-lg-inline d-xl-inline"}> Back</span>
            </Link>
          ) : <span className={"nav-disabled"}><FontAwesomeIcon icon={faPlay} flip={"horizontal"}
                                                                fixedWidth={true}/> <span
            className={"d-none d-md-inline d-lg-inline d-xl-inline"}>Back</span></span>}

          {/* Current Position */}
          {page ? (
              <span>Page {page.frontmatter.page} of {issue.frontmatter.pages}</span>
            ) :
            <span>{issue.frontmatter.pages} pages</span>
          }

          {/* Next Page */}
          {nextPage ? (
              <Link to={nextPage.fields.slug} rel="next" title={'next page'}>
                <span className={"d-none d-md-inline d-lg-inline d-xl-inline"}>Next </span><FontAwesomeIcon icon={faPlay}
                                                                                                            fixedWidth={true}/>
              </Link>
            ) :
            <span className={"nav-disabled"}><span className={"d-none d-md-inline d-lg-inline d-xl-inline"}>Next </span><FontAwesomeIcon
              icon={faPlay} fixedWidth={true}/></span>}

          {/* Latest Page */}
          {latestPage ? (
            <Link to={latestPage.fields.slug} title={'latest page'}>
              <span className={"d-none d-md-inline d-lg-inline d-xl-inline"}>Latest </span><FontAwesomeIcon
              icon={faFastForward} fixedWidth={true}/>
            </Link>
          ) : <span className={"nav-disabled"}><span
            className={"d-none d-md-inline d-lg-inline d-xl-inline"}>Latest </span><FontAwesomeIcon icon={faFastForward}
                                                                                                    fixedWidth={true}/></span>}

        </nav>
      </div>
    </div>
  )
}

export default ComicReader
