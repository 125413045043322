import React from "react"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import TapeLabelHeader from "../headers/tape-label-header"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAmazon,
  faArtstation, faBehance, faDeviantart,
  faFacebook, faFlickr,
  faGoodreads,
  faImdb,
  faInstagram, faItunes,
  faLinkedin, faPatreon, faSoundcloud, faSpotify, faTumblr,
  faTwitter, faVimeo, faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import _ from "lodash"
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"

class BioBox extends React.Component {

  constructor(props) {
    super(props)
    this.state = { hover: false, mobileSelect: false }
    this.wrapperRef = React.createRef()
  }

  render() {

    const it = this

    const { credit, role } = this.props

    let node = credit
    let personName = node.alternateName || node.name
    let twitterLink, instagramLink, imdbLink, goodReadsLink, facebookLink, linkedinLink, artStationLink,
      deviantArtLink, behanceLink, amazonLink, patreonLink, youtubeLink, tumblrLink, flickrLink,
      soundcloudLink, itunesLink, spotifyLink, vimeoLink, webLink

    webLink = node.website

    _.map(node.sameAs, link => {
      if (link.includes("twitter")) twitterLink = link
      else if (link.includes("instagram")) instagramLink = link
      else if (link.includes("imdb")) imdbLink = link
      else if (link.includes("goodreads")) goodReadsLink = link
      else if (link.includes("facebook")) facebookLink = link
      else if (link.includes("linkedin")) linkedinLink = link
      else if (link.includes("artstation")) artStationLink = link
      else if (link.includes("deviantart")) deviantArtLink = link
      else if (link.includes("behance")) behanceLink = link
      else if (link.includes("amazon")) amazonLink = link
      else if (link.includes("patreon")) patreonLink = link
      else if (link.includes("youtube")) youtubeLink = link
      else if (link.includes("vimeo")) vimeoLink = link
      else if (link.includes("tumblr")) tumblrLink = link
      else if (link.includes("flickr")) flickrLink = link
      else if (link.includes("spotify")) spotifyLink = link
      else if (link.includes("itunes")) itunesLink = link
      else if (link.includes("soundcloud")) soundcloudLink = link
    })

    return (
      <div
        ref={it.wrapperRef}
        key={personName}
        css={css`
                  height: 300px;
                  overflow: hidden;
                  display: flex;
                  padding: 10px;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-end;
                  flex: 0 50%;
                  text-align: center;
                  @media screen and (min-width: 480px) {
                    flex: 0 50%;
                  }
                  @media screen and (min-width: 540px) {
                    flex: 0 33%;
                  }
                  @media screen and (min-width: 740px) {
                    flex: 0 25%;
                  }
                  @media screen and (min-width: 960px) {
                    flex: 0 20%;
                  }
                  @media screen and (min-width: 1100px) {
                    flex: 0 16.5%;
                  }
                  
                  h1 { margin: 0; }
                `}
      >
          {node.image && <Img
            alt={personName}
            fluid={node.image.childImageSharp.fluid}
            css={css`
              height:300px;
              object-fit: cover;
              @media screen and (max-width: 480px) {
                height:200px;
              }
            `}
          />}
          <div css={css`background: #101010; padding: 4px 4px 6px;`}>
            {webLink && <OutboundLink href={webLink} target={'_blank'}><TapeLabelHeader small>{personName}{role && ` (${role.join(", ")})`}</TapeLabelHeader></OutboundLink>}
            {!webLink && <TapeLabelHeader small>{personName}{role && ` (${role.join(", ")})`}</TapeLabelHeader>}
          </div>
          <div
            css={css`
                          background: #101010;
                          display:flex;
                          flex-direction: row;
                          flex-wrap:wrap;
                          justify-content: space-evenly;
                          font-size:24px;
                          
                          a {
                            color: dimgrey !important;
                            &:hover {
                              color: white !important;
                              background-color: transparent;
                            }
                          }
                      `}>
            {webLink &&
            <OutboundLink target={"_blank"} href={webLink}><FontAwesomeIcon title={"link"}
                                                                            icon={faExternalLink}/></OutboundLink>}

            {twitterLink &&
            <OutboundLink target={"_blank"} href={twitterLink}><FontAwesomeIcon title={"twitter"}
                                                                                icon={faTwitter}/></OutboundLink>}
            {instagramLink &&
            <OutboundLink target={"_blank"} href={instagramLink}><FontAwesomeIcon title={"instagram"}
                                                                                  icon={faInstagram}/></OutboundLink>}
            {imdbLink &&
            <OutboundLink target={"_blank"} href={imdbLink}><FontAwesomeIcon title={"imdb"}
                                                                             icon={faImdb}/></OutboundLink>}
            {goodReadsLink &&
            <OutboundLink target={"_blank"} href={goodReadsLink}><FontAwesomeIcon title={"goodreads"}
                                                                                  icon={faGoodreads}/></OutboundLink>}
            {facebookLink &&
            <OutboundLink target={"_blank"} href={facebookLink}><FontAwesomeIcon title={"facebook"}
                                                                                 icon={faFacebook}/></OutboundLink>}
            {linkedinLink &&
            <OutboundLink target={"_blank"} href={linkedinLink}><FontAwesomeIcon title={"linkedin"}
                                                                                 icon={faLinkedin}/></OutboundLink>}
            {artStationLink &&
            <OutboundLink target={"_blank"} href={artStationLink}><FontAwesomeIcon title={"artstation"}
                                                                                   icon={faArtstation}/></OutboundLink>}
            {deviantArtLink &&
            <OutboundLink target={"_blank"} href={deviantArtLink}><FontAwesomeIcon title={"deviantart"}
                                                                                   icon={faDeviantart}/></OutboundLink>}
            {behanceLink &&
            <OutboundLink target={"_blank"} href={behanceLink}><FontAwesomeIcon title={"behance"}
                                                                                icon={faBehance}/></OutboundLink>}
            {amazonLink &&
            <OutboundLink target={"_blank"} href={amazonLink}><FontAwesomeIcon title={"amazon"}
                                                                               icon={faAmazon}/></OutboundLink>}
            {patreonLink &&
            <OutboundLink target={"_blank"} href={patreonLink}><FontAwesomeIcon title={"patreon"}
                                                                                icon={faPatreon}/></OutboundLink>}
            {youtubeLink &&
            <OutboundLink target={"_blank"} href={youtubeLink}><FontAwesomeIcon title={"youtube"}
                                                                                icon={faYoutube}/></OutboundLink>}
            {vimeoLink && <OutboundLink target={"_blank"} href={vimeoLink}><FontAwesomeIcon title={"vimeo"}
                                                                                            icon={faVimeo}/></OutboundLink>}
            {tumblrLink &&
            <OutboundLink target={"_blank"} href={tumblrLink}><FontAwesomeIcon title={"tumblr"}
                                                                               icon={faTumblr}/></OutboundLink>}
            {flickrLink &&
            <OutboundLink target={"_blank"} href={flickrLink}><FontAwesomeIcon title={"flickr"}
                                                                               icon={faFlickr}/></OutboundLink>}
            {soundcloudLink &&
            <OutboundLink target={"_blank"} href={soundcloudLink}><FontAwesomeIcon title={"soundcloud"}
                                                                                   icon={faSoundcloud}/></OutboundLink>}
            {itunesLink &&
            <OutboundLink target={"_blank"} href={itunesLink}><FontAwesomeIcon title={"itunes"}
                                                                               icon={faItunes}/></OutboundLink>}
            {spotifyLink &&
            <OutboundLink target={"_blank"} href={spotifyLink}><FontAwesomeIcon title={"spotify"}
                                                                                icon={faSpotify}/></OutboundLink>}
          </div>
      </div>
    )
  }
}

export default BioBox